import React from "react"
import { EditableText } from "../../../../../packages/editing/EditableText"
import { useNavigate } from "../../../../../packages/hooks/useNavigate"
import { ImageToUrl } from "../../../../../reactor/Types/File"
import { usePageContext } from "../../Page"
import { usePartyContext } from "./PartyContext"
import { colors } from "../../colors"
import { useLocalize } from "../../../../../packages/localization/client-side/useLocalize"
import { useParams } from "react-router-dom"

export function ConfirmationPage() {
    const { adyenOrderId } = useParams()
    const page = usePageContext()
    const localize = useLocalize()
    const { section, email } = usePartyContext()
    const navigate = useNavigate()
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflowX: "clip",
                padding: 32,
                animation: "fadeIn 2s",
            }}>
            <div
                style={{
                    width: "100%",
                    maxWidth: 512,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                <h1>
                    <EditableText
                        obj={section}
                        prop="confirmationHeader"
                        isLocalized={true}
                        defaultText="Your party is booked!"
                    />
                </h1>
                <EditableText
                    obj={section}
                    prop="confirmationText"
                    isLocalized={true}
                    defaultText="A confirmation has been sent to your email address."
                />
                <EditableText
                    obj={section.summaryPage}
                    prop="receiptInformation"
                    style={{ marginBottom: 32 }}
                    macros={{
                        email: <span style={{ color: colors.pinkFlirt }}>{email}</span>,
                    }}
                />
                {adyenOrderId && (
                    <strong style={{ marginTop: 32 }}>
                        {`${localize(section.referenceText)} ${adyenOrderId}`}
                    </strong>
                )}
                <div
                    onClick={() => navigate(`/${page.locale.valueOf()}/${page.venue?.slug ?? ""}`)}
                    style={{ marginTop: 64, color: colors.pinkFlirt, cursor: "pointer" }}>
                    <EditableText obj={section} prop="backToHomePage" />
                </div>
                {section.confirmationImage && (
                    <img
                        style={{ maxWidth: 720, marginTop: 86 }}
                        src={ImageToUrl(section.confirmationImage, { width: 720 })}
                    />
                )}
            </div>
        </div>
    )
}
