import { css } from "@emotion/react"
import { putCookieConsents, useCookieConsents } from "../../studio/client"
import { ColorStyles } from "../ui"
import { useState } from "react"
import { useLocalize } from "../localization/client-side/useLocalize"

/**
 * A default view for presenting cookie consent information and options to the
 * user. This only displays a banner if the user has not yet given consent.
 *
 * This implements a default popup, with allow all, allow selection (with
 * switches for individual consents), and deny buttons.
 *
 * To customize for a particular customer, you can implement your own component,
 * or fork this file.
 */
export function CookieConsentView() {
    const { data, refresh } = useCookieConsents()

    const localize = useLocalize()
    const [necessary, setNecessary] = useState(true)
    const [preferences, setPreferences] = useState(false)
    const [statistics, setStatistics] = useState(false)
    const [marketing, setMarketing] = useState(false)

    if (data?.consents || !data?.info) return null

    return (
        <div
            css={css`
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 32px;
                background-color: ${data.info.colorMode === "dark" ? "#000e" : "#fff"};
                color: ${data.info.colorMode === "light" ? "black" : "white"};
                display: flex;
                flex-direction: row;
                z-index: 1000;
                box-shadow: 0 -4px 16px rgba(0, 0, 0, 0.1);
                overflow-y: scroll;

                @media (max-width: 600px) {
                    flex-direction: column;
                    top: 0;
                    margin: 16px;
                    margin-top: 32px;
                    margin-bottom: 32px;
                    border-radius: 8px;
                    font-size: 12px;
                }
            `}>
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                {data.info.logo && (
                    <img
                        src={data.info.logo}
                        alt=""
                        css={css`
                            width: 50%;
                            max-width: 96px;

                            @media (max-width: 600px) {
                                max-width: 64px;
                                margin-bottom: 16px;
                            }
                        `}
                    />
                )}
            </div>
            <div
                style={{
                    flex: 2,
                    paddingRight: 32,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}>
                <div style={{ marginBottom: 8 }}>
                    <b>{localize(data.info.title)}</b>
                </div>
                <div>{localize(data.info.description)}</div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        marginTop: 16,
                        flexWrap: "wrap",
                    }}>
                    <Switch
                        label={localize(data.info.necessary)}
                        value={necessary}
                        onChange={setNecessary}
                        disabled={true}
                    />
                    <Switch
                        label={localize(data.info.preferences)}
                        value={preferences}
                        onChange={setPreferences}
                    />
                    <Switch
                        label={localize(data.info.statistics)}
                        value={statistics}
                        onChange={setStatistics}
                    />
                    <Switch
                        label={localize(data.info.marketing)}
                        value={marketing}
                        onChange={setMarketing}
                    />
                </div>
            </div>
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: 32,
                }}>
                <div
                    style={{
                        backgroundColor: ColorStyles.primary[700],
                        borderRadius: 8,
                        color: "white",
                        marginBottom: 8,
                        textAlign: "center",
                        padding: 12,
                        cursor: "pointer",
                    }}
                    onClick={async () => {
                        await putCookieConsents({
                            necessary: true,
                            marketing: true,
                            preferences: true,
                            statistics: true,
                        })
                        if (GiveCookieConsent) {
                            // Calling GiveCookieConsent will execute all
                            // callbacks registered with WithCookieConsent for
                            // the specified type. This allows the consents to
                            // take effect without reloading the page.
                            GiveCookieConsent("necessary")
                            GiveCookieConsent("marketing")
                            GiveCookieConsent("preferences")
                            GiveCookieConsent("statistics")
                        }
                        await refresh()
                    }}>
                    {localize(data.info.allowAll)}
                </div>
                <div
                    style={{
                        backgroundColor: ColorStyles.primary[100],
                        borderRadius: 8,
                        color: "#333",
                        marginBottom: 8,
                        textAlign: "center",
                        padding: 12,
                        cursor: "pointer",
                    }}
                    onClick={async () => {
                        await putCookieConsents({
                            necessary: true, // Necessary cookies are always enabled
                            marketing,
                            preferences,
                            statistics,
                        })
                        await refresh()
                        if (GiveCookieConsent && necessary) GiveCookieConsent("necessary")
                        if (GiveCookieConsent && preferences) GiveCookieConsent("preferences")
                        if (GiveCookieConsent && statistics) GiveCookieConsent("statistics")
                        if (GiveCookieConsent && marketing) GiveCookieConsent("marketing")
                    }}>
                    {localize(data.info.allowSelection)}
                </div>
                <div
                    style={{
                        backgroundColor: ColorStyles.primary[100],
                        borderRadius: 8,
                        color: "#333",
                        marginBottom: 8,
                        textAlign: "center",
                        padding: 12,
                        cursor: "pointer",
                    }}
                    onClick={async () => {
                        await putCookieConsents({
                            necessary: true, // Necessary cookies are always enabled
                            marketing: false,
                            preferences: false,
                            statistics: false,
                        })
                        await refresh()
                        if (GiveCookieConsent) GiveCookieConsent("necessary")
                    }}>
                    {localize(data.info.deny)}
                </div>
            </div>
        </div>
    )
}

// A sliding switch component that can be used to toggle a boolean value.
function Switch({
    label,
    value,
    onChange,
    disabled,
}: {
    label: string
    value: boolean
    onChange: (value: boolean) => void
    disabled?: boolean
}) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}>
            {label}

            <div
                css={css`
                    position: relative;
                    margin: 8px;
                    width: 50px;
                    height: 24px;
                    background-color: ${value ? ColorStyles.primary[700] : ColorStyles.gray[300]};
                    transition: background-color 0.2s;
                    border-radius: 12px;
                    cursor: ${disabled ? "not-allowed" : "pointer"};
                    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.4);
                `}
                onClick={() => {
                    if (!disabled) onChange(!value)
                }}>
                <div
                    css={css`
                        position: absolute;
                        top: 0;
                        left: ${value ? "26px" : "0"};
                        width: 24px;
                        height: 24px;
                        background-color: white;
                        border-radius: 50%;
                        transition: left 0.2s;
                        box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
                    `}
                />
            </div>
        </div>
    )
}
