'use strict';

var xtend = require('xtend');

var unified = require('unified');

var parse = require('remark-parse');

var PropTypes = require('prop-types');

var addListMetadata = require('mdast-add-list-metadata');

var naiveHtml = require('./plugins/naive-html');

var disallowNode = require('./plugins/disallow-node');

var astToReact = require('./ast-to-react');

var wrapTableRows = require('./wrap-table-rows');

var getDefinitions = require('./get-definitions');

var uriTransformer = require('./uri-transformer');

var defaultRenderers = require('./renderers');

var symbols = require('./symbols');

var allTypes = Object.keys(defaultRenderers);

var ReactMarkdown = function ReactMarkdown(props) {
  // To do in next major: remove `source`.
  var src = props.source || props.children || '';

  if (props.allowedTypes && props.disallowedTypes) {
    throw new Error('Only one of `allowedTypes` and `disallowedTypes` should be defined');
  }

  var renderers = xtend(defaultRenderers, props.renderers);
  var processor = unified().use(parse).use(props.plugins || []); // eslint-disable-next-line no-sync

  var tree = processor.runSync(processor.parse(src));
  var renderProps = xtend(props, {
    renderers: renderers,
    definitions: getDefinitions(tree)
  });
  determineAstToReactTransforms(props).forEach(function (transform) {
    tree = transform(tree, renderProps);
  });
  return tree;
};

function determineAstToReactTransforms(props) {
  var transforms = [wrapTableRows, addListMetadata()];
  var disallowedTypes = props.disallowedTypes;

  if (props.allowedTypes) {
    disallowedTypes = allTypes.filter(function (type) {
      return type !== 'root' && props.allowedTypes.indexOf(type) === -1;
    });
  }

  var removalMethod = props.unwrapDisallowed ? 'unwrap' : 'remove';

  if (disallowedTypes && disallowedTypes.length > 0) {
    transforms.push(disallowNode.ofType(disallowedTypes, removalMethod));
  }

  if (props.allowNode) {
    transforms.push(disallowNode.ifNotMatch(props.allowNode, removalMethod));
  } // To do in next major: remove `escapeHtml`.


  var renderHtml = (props.allowDangerousHtml || props.escapeHtml === false) && !props.skipHtml;
  var hasHtmlParser = (props.astPlugins || []).some(function (transform) {
    return transform.identity === symbols.HtmlParser;
  });

  if (renderHtml && !hasHtmlParser) {
    transforms.push(naiveHtml);
  }

  if (props.astPlugins) {
    transforms = transforms.concat(props.astPlugins);
  } // Add the final transform to turn everything into React.


  transforms.push(astToReact);
  return transforms;
}

ReactMarkdown.defaultProps = {
  transformLinkUri: uriTransformer
};
ReactMarkdown.propTypes = {
  className: PropTypes.string,
  source: PropTypes.string,
  children: PropTypes.string,
  sourcePos: PropTypes.bool,
  rawSourcePos: PropTypes.bool,
  escapeHtml: PropTypes.bool,
  allowDangerousHtml: PropTypes.bool,
  skipHtml: PropTypes.bool,
  allowNode: PropTypes.func,
  allowedTypes: PropTypes.arrayOf(PropTypes.oneOf(allTypes)),
  disallowedTypes: PropTypes.arrayOf(PropTypes.oneOf(allTypes)),
  transformLinkUri: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  linkTarget: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  transformImageUri: PropTypes.func,
  astPlugins: PropTypes.arrayOf(PropTypes.func),
  unwrapDisallowed: PropTypes.bool,
  renderers: PropTypes.object,
  plugins: PropTypes.array
};
ReactMarkdown.types = allTypes;
ReactMarkdown.renderers = defaultRenderers;
ReactMarkdown.uriTransformer = uriTransformer;
module.exports = ReactMarkdown;