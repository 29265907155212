'use strict'

var fromCharCode = require('../constant/from-char-code.js')

function regexCheck(regex) {
  return check

  function check(code) {
    return regex.test(fromCharCode(code))
  }
}

module.exports = regexCheck
