import { OpaqueInteger, OpaqueNumber, RangedInteger, RangedNumber } from "../Opaque"
import { TypeValidator } from "./TypeValidator"

/**
 * ISO 4217 currency code.
 *
 * This is a three-letter code that represents a currency. It is case-insensitive.
 *
 * Source: https://www.currency-iso.org/dam/downloads/lists/list_one.xml
 */
export type CurrencyCode = (typeof currencyCodes)[number]

/**
 * Constructs a currency code from a string. The string must be a valid ISO 4217
 * currency code, or an error will be thrown.
 */
export function CurrencyCode(currency: string): CurrencyCode {
    const currencyCode = currency as CurrencyCode
    if (currencyCodes.includes(currencyCode)) return currencyCode
    throw new Error(`${currency} is not a recognized currency code`)
}

/**
 * List of all ISO 4217 currency codes.
 *
 * Source: https://www.html-code-generator.com/javascript/json/currency-name
 */
export const currencyCodes = [
    "AFA",
    "ALL",
    "DZD",
    "AOA",
    "ARS",
    "AMD",
    "AWG",
    "AUD",
    "AZN",
    "BSD",
    "BHD",
    "BDT",
    "BBD",
    "BYR",
    "BEF",
    "BZD",
    "BMD",
    "BTN",
    "BTC",
    "BOB",
    "BAM",
    "BWP",
    "BRL",
    "GBP",
    "BND",
    "BGN",
    "BIF",
    "KHR",
    "CAD",
    "CVE",
    "KYD",
    "XOF",
    "XAF",
    "XPF",
    "CLP",
    "CNY",
    "COP",
    "KMF",
    "CDF",
    "CRC",
    "HRK",
    "CUC",
    "CZK",
    "DKK",
    "DJF",
    "DOP",
    "XCD",
    "EGP",
    "ERN",
    "EEK",
    "ETB",
    "EUR",
    "FKP",
    "FJD",
    "GMD",
    "GEL",
    "DEM",
    "GHS",
    "GIP",
    "GRD",
    "GTQ",
    "GNF",
    "GYD",
    "HTG",
    "HNL",
    "HKD",
    "HUF",
    "ISK",
    "INR",
    "IDR",
    "IRR",
    "IQD",
    "ILS",
    "ITL",
    "JMD",
    "JPY",
    "JOD",
    "KZT",
    "KES",
    "KWD",
    "KGS",
    "LAK",
    "LVL",
    "LBP",
    "LSL",
    "LRD",
    "LYD",
    "LTL",
    "MOP",
    "MKD",
    "MGA",
    "MWK",
    "MYR",
    "MVR",
    "MRO",
    "MUR",
    "MXN",
    "MDL",
    "MNT",
    "MAD",
    "MZM",
    "MMK",
    "NAD",
    "NPR",
    "ANG",
    "TWD",
    "NZD",
    "NIO",
    "NGN",
    "KPW",
    "NOK",
    "OMR",
    "PKR",
    "PAB",
    "PGK",
    "PYG",
    "PEN",
    "PHP",
    "PLN",
    "QAR",
    "RON",
    "RUB",
    "RWF",
    "SVC",
    "WST",
    "SAR",
    "RSD",
    "SCR",
    "SLL",
    "SGD",
    "SKK",
    "SBD",
    "SOS",
    "ZAR",
    "KRW",
    "XDR",
    "LKR",
    "SHP",
    "SDG",
    "SRD",
    "SZL",
    "SEK",
    "CHF",
    "SYP",
    "STD",
    "TJS",
    "TZS",
    "THB",
    "TOP",
    "TTD",
    "TND",
    "TRY",
    "TMT",
    "UGX",
    "UAH",
    "AED",
    "UYU",
    "USD",
    "UZS",
    "VUV",
    "VEF",
    "VND",
    "YER",
    "ZMK",
] as const

/**
 * Integer currency amount in minor units.
 *
 * The input value will be rounded to the nearest integer using the provided
 * rounding function. The default is Math.round.
 *
 * E.g. 3.50 USD would be represented as 350.
 */
export type CurrencyMinorUnits = OpaqueInteger<"CurrencyMinorUnits">

TypeValidator(CurrencyMinorUnits)
export function CurrencyMinorUnits(minorUnits: number, rounding = Math.round): CurrencyMinorUnits {
    if (typeof minorUnits !== "number")
        throw new Error(`CurrencyMinorUnits must be a number, got ${minorUnits}`)

    minorUnits = rounding(minorUnits)
    if (!Number.isInteger(minorUnits))
        throw new Error(`CurrencyMinorUnits must be an integer, got ${minorUnits}`)

    return minorUnits as any as CurrencyMinorUnits
}

/**
 * Integer currency amount in minor units, which must be non-negative.
 */
export type CurrencyMinorUnitsNonNegative = RangedInteger<
    "CurrencyMinorUnitsNonNegative",
    0,
    undefined
>
TypeValidator(CurrencyMinorUnitsNonNegative)
export function CurrencyMinorUnitsNonNegative(
    minorUnits: number,
    rounding = Math.round
): CurrencyMinorUnitsNonNegative {
    if (minorUnits < 0) {
        throw new Error(`CurrencyMinorUnitsNonNegative must be non-negative, got ${minorUnits}`)
    }
    return CurrencyMinorUnits(minorUnits, rounding) as any as CurrencyMinorUnitsNonNegative
}

/**
 * Converts a currency amount to major units (e.g. dollars, euros, etc.).
 *
 * For most currencies, the major unit is 100 times the minor unit. For a few,
 * handpicked currencies (BHD, IQD, JOD, KWD, LYD, OMR, TND) it is 1000 times
 * the minor unit. This function returns the major unit amount for the given
 * currency.
 *
 * Note that this is a simple division, and does not take into account any
 * rounding or formatting rules. Due to JavaScript's handling of floating point
 * numbers, the result may not be exactly what you expect.
 *
 */
export function CurrencyMinorUnitsToMajorUnits(
    a: CurrencyMinorUnits,
    currency: CurrencyCode
): CurrencyMajorUnits {
    switch (currency) {
        case "BHD":
        case "IQD":
        case "JOD":
        case "KWD":
        case "LYD":
        case "OMR":
        case "TND":
            return CurrencyMajorUnits(a.valueOf() / 1000)
    }

    return CurrencyMajorUnits(a.valueOf() / 100)
}

/**
 * Integer currency amount in major units.
 *
 * E.g. 3.50 USD would be represented as the number `3.50`.
 *
 * This type is used for convenience, and should not be used for calculations.
 * Use `CurrencyMinorUnits`, or the `CurrencyAmount` type for that.
 */
export type CurrencyMajorUnits = OpaqueNumber<"CurrencyMajorUnits">
TypeValidator(CurrencyMajorUnits)
export function CurrencyMajorUnits(
    majorUnits: number | CurrencyMajorUnitsNonNegative
): CurrencyMajorUnits {
    if (typeof majorUnits !== "number")
        throw new Error(`CurencyMajorUnits must be a number, got ${majorUnits}`)

    return majorUnits as any as CurrencyMajorUnits
}

/**
 * Currency amount in major units, which must be non-negative.
 *
 * E.g. 3.50 USD would be represented as the number `3.50`.
 */
export type CurrencyMajorUnitsNonNegative = RangedNumber<
    "CurrencyMajorUnitsNonNegative",
    0,
    undefined
>
TypeValidator(CurrencyMajorUnitsNonNegative)
export function CurrencyMajorUnitsNonNegative(majorUnits: number): CurrencyMajorUnitsNonNegative {
    if (majorUnits < 0) {
        throw new Error(`CurrencyMajorUnitsNonNegative must be non-negative, got ${majorUnits}`)
    }
    return majorUnits as any as CurrencyMajorUnitsNonNegative
}

export const CurrencyZero = 0 as CurrencyMajorUnits &
    CurrencyMinorUnits &
    CurrencyMajorUnitsNonNegative &
    CurrencyMinorUnitsNonNegative

/**
 * Represents a currency amount. The amount is stored as an integer amount of
 * minor units (e.g. cents, pence, etc.). The currency code is a three-letter
 * ISO 4217 code.
 *
 * In addition, the currency amount converted to major units is provided for
 * convenience. This is the same value as the `amountMinorUnits`, and does not
 * take into account any rounding or formatting rules. Due to JavaScript's
 * handling of floating point numbers, the result may not be exactly what you
 * expect.
 *
 * The returned structure is immutable, and should only be constructed using the
 * `CurrencyAmount` function to ensure the fields are consistent. To change the
 * amount, use the add, subtract, multiply, or divide functions.
 *
 * This type not a class, but provides a constructor function with helper
 * functions, e.g. `CurrencyAmount.add()`. This allows the `CurrencyAmount` type
 * to be stored in a database or serialized to JSON without losing the type
 * information.
 */
export type CurrencyAmount = {
    /**
     * The full amount in minor units (e.g. cents, pence, etc.)
     *
     * E.g. `3.50` USD would be represented as the integer `350`.
     */
    readonly minorUnits: CurrencyMinorUnits

    /**
     * The full amount in major units (e.g. dollars, euros, etc.), provided for
     * convenience. In case of discrepancies due to floating-point inaccuracy,
     * the `amountMinorUnits` field should be considered the source of truth.
     *
     * This semantically the same value as the `amountMinorUnits`, and does not
     * take into account any rounding or formatting rules. Due to JavaScript's
     * handling of floating point numbers, the result may not be exactly what
     * you expect.
     */
    readonly majorUnits: CurrencyMajorUnits

    /**
     * The currency code.
     */
    readonly currency: CurrencyCode
}
export function CurrencyAmount(
    minorUnits: CurrencyMinorUnits,
    currency: CurrencyCode
): CurrencyAmount {
    return Object.freeze({
        minorUnits,
        majorUnits: CurrencyMinorUnitsToMajorUnits(minorUnits, currency),
        currency,
    })
}
CurrencyAmount.add = (a: CurrencyAmount, b: CurrencyAmount): CurrencyAmount => {
    if (a.currency !== b.currency)
        throw new Error(
            `Cannot add amounts in different currencies: ${a.currency} and ${b.currency}`
        )
    const sum = a.minorUnits.valueOf() + b.minorUnits.valueOf()
    return CurrencyAmount(CurrencyMinorUnits(sum), a.currency)
}
CurrencyAmount.subtract = (a: CurrencyAmount, b: CurrencyAmount): CurrencyAmount => {
    if (a.currency !== b.currency)
        throw new Error(
            `Cannot subtract amounts in different currencies: ${a.currency} and ${b.currency}`
        )

    const difference = a.minorUnits.valueOf() - b.minorUnits.valueOf()
    return CurrencyAmount(CurrencyMinorUnits(difference), a.currency)
}
CurrencyAmount.multiply = (
    a: CurrencyAmount,
    b: number,
    /**
     * The rounding function to use to convert to an integer amount of minor
     * units. Defaults to Math.round, but can be set to e.g. Math.floor or
     * Math.ceil.
     */
    rounding = Math.round
): CurrencyAmount => {
    const product = a.minorUnits.valueOf() * b
    return CurrencyAmount(CurrencyMinorUnits(product, rounding), a.currency)
}
CurrencyAmount.divide = (
    a: CurrencyAmount,
    b: number,
    /**
     * The rounding function to use to convert to an integer amount of minor
     * units. Defaults to Math.round, but can be set to e.g. Math.floor or Math.ceil.
     */
    rounding = Math.round
): CurrencyAmount => {
    const quotient = a.minorUnits.valueOf() / b
    return CurrencyAmount(CurrencyMinorUnits(quotient, rounding), a.currency)
}

/**
 * Formats a currency amount as a string (in major units) according to the
 * specified locale and options. If no locale or options are specified, the
 * runtime's default locale is used.
 */
CurrencyAmount.format = (
    /**
     * The amount of currency.
     */
    amount: CurrencyAmount,
    /**
     * The locale to use for formatting. If not specified, the runtime's default
     * locale is used.
     */
    locale?: Intl.LocalesArgument,
    /**
     * Options to pass to the Intl.NumberFormat constructor.
     *
     * The defaults `{ style: "currency", currency: amount.currency }` will be
     * set unless overridden by this parameter.
     */
    options?: Intl.NumberFormatOptions
): string => {
    const format = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: amount.currency,
        ...options,
    })
    return format.format(amount.majorUnits.valueOf())
}
