import React from "react"
import { Link } from "react-router-dom"
import { useEditableContext } from "../../../../packages/editing/EditableContext"
import { EditableText } from "../../../../packages/editing/EditableText"
import { useHover } from "../../../../packages/hooks/useHover"

import { Localized } from "../../../../packages/localization/Localized"
import { colors } from "../colors"
import { usePageContext } from "../Page"
import { Markdown } from "../../../../reactor/Types/Primitives/Markdown"
import { Section } from "../../../../packages/editing/Section"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"

Section(TextAndCallToAction)
function TextAndCallToAction(section: {
    title?: Localized<string>
    titleAlign?: "left" | "center" | "right"
    body?: Localized<Markdown>
    callToAction?: Localized<string>
    callToActionLink?: string
    inverted?: boolean
}) {
    const { venue, locale } = usePageContext()
    const localize = useLocalize()

    let cta = section.callToAction ? (
        <CallToAction
            align="flex-start"
            section={section as { callToAction: Localized<string> }}
            prop="callToAction"
        />
    ) : undefined

    if (section.callToActionLink) {
        cta = (
            <Link
                to={
                    section.callToActionLink.startsWith("https://")
                        ? section.callToActionLink
                        : `/${locale}/${venue ? venue.slug + "/" : ""}${section.callToActionLink}`
                }>
                {section.callToAction && (
                    <CallToAction
                        align="flex-start"
                        section={section as { callToAction: Localized<string> }}
                        prop="callToAction"
                    />
                )}
            </Link>
        )
    }

    return (
        <div
            className="container"
            style={{
                maxWidth: 720,
                width: "100%",
                marginBottom: 32,
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
            }}>
            {section.title && (
                <h2
                    style={{
                        textAlign: section.titleAlign ?? "left",
                        marginTop: 32,
                        color: section.inverted ? "white" : undefined,
                    }}>
                    <EditableText
                        obj={section}
                        prop="title"
                        isLocalized={true}
                        defaultText="Title goes here"
                        macros={venue?.name ? { venue: localize(venue?.name) } : {}}
                    />
                </h2>
            )}

            <EditableText
                obj={section}
                prop="body"
                isLocalized={true}
                isMarkdown={true}
                defaultText="Body goes here"
                style={{
                    marginTop: 16,
                    marginBottom: 32,
                    fontSize: 20,
                    color: section.inverted ? "white" : undefined,
                    lineHeight: 1.6,
                }}
            />
            {cta}
        </div>
    )
}

export function CallToAction<T>({
    section,
    prop,
    onClick,
    disabled,
    disabledReason,
    align,
    marked,
    textStyle,
}: {
    section: T
    prop: keyof T
    onClick?: () => void
    disabled?: boolean
    disabledReason?: string
    align?: "flex-start" | "center" | "flex-end"
    marked?: boolean
    textStyle?: React.CSSProperties
}) {
    const { editing } = useEditableContext()
    const [showReason, setShowReason] = React.useState(false)
    const { hover, hoverProps } = useHover()
    return (
        <div
            {...hoverProps}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: align ?? "center",
            }}>
            {showReason ? disabledReason : undefined}
            {marked ? (
                <div
                    onClick={onClick}
                    style={{
                        userSelect: editing ? undefined : "none",
                        cursor: disabled ? undefined : "pointer",
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 24,
                        paddingRight: 24,
                        transform: hover ? "scale(1.05) rotate(2deg)" : undefined,
                        transition: "transform 0.2s",
                        backgroundColor: colors.grass,
                        color: "white",
                        fontSize: 20,
                        borderRadius: 30,
                        fontFamily: "GT-Haptik-Black",
                    }}>
                    ✔
                </div>
            ) : (
                <EditableText
                    onClick={disabled ? () => setShowReason(true) : editing ? undefined : onClick}
                    obj={section}
                    prop={prop}
                    isLocalized={true}
                    style={{
                        userSelect: editing ? undefined : "none",
                        cursor: disabled ? undefined : "pointer",
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 24,
                        paddingRight: 24,
                        transform: hover ? "scale(1.05) rotate(2deg)" : undefined,
                        transition: "transform 0.2s",
                        backgroundColor: disabled ? colors.grey5 : colors.pinkFlirt,
                        color: "white",
                        fontSize: 20,
                        borderRadius: 30,
                        fontFamily: "GT-Haptik-Black",
                        textTransform: "uppercase",
                        ...textStyle,
                    }}
                />
            )}
        </div>
    )
}
